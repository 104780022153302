import axios from '../authAxios'

export async function login( email: string, password: string ) {
    const res = await axios.post('/login', { email, password }, { withCredentials: true })
        .then(
            (resp) => {
                localStorage.setItem('access', JSON.stringify(resp.data.access));
                localStorage.setItem('refresh', JSON.stringify(resp.data.refresh));
            }
        )

}