import Container from "react-bootstrap/Container";
import Nav from 'react-bootstrap/Nav'
import Navbar from 'react-bootstrap/Navbar'
import NavDropdown from 'react-bootstrap/NavDropdown'
import LoadingNavbar from "./loadingNavbar";
import { Avatar } from "@mui/material";
import { useAuthContext, useAuthDispatch } from "../../modules/authContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRightFromBracket, faGear } from "@fortawesome/free-solid-svg-icons";
import { faUser, faIdBadge } from "@fortawesome/free-regular-svg-icons";

const MainNavbar = () => {
    const { user, authenticated, loading } = useAuthContext();
    const dispatch = useAuthDispatch();

    let color = 'rgba(11,110,212, .75)'

    if (loading && user) {
        return <LoadingNavbar />
    }

    if(!user) {
        return <div></div>
    }

    return (
        <>
            <Navbar bg={'ligth'} expand={'lg'}>
                <Container fluid>
                    <Navbar.Brand href={'/'} className={'text-dark'}>
                        ICMS
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls={'navbarCollapse'} />
                    <Navbar.Collapse id={'navbarScroll'}>
                        <Nav
                            className={'me-auto my-2 my-lg-0'}
                            style={{ maxHeight: '100px' }}
                            navbarScroll
                        >
                            <Nav.Link href={'/'} className={'text-dark'}>Home page</Nav.Link>
                            {authenticated && (
                                <>
                                    <Nav.Link href={'/'} className={'text-dark'}>Worker</Nav.Link>
                                    <Nav.Link href={'/'} className={'text-dark'}>Payments</Nav.Link>
                                    <Nav.Link href={'/datatracker'} className={'text-dark'}>Data Tracker</Nav.Link>
                                    <Nav.Link href={'/'} className={'text-dark'}>Projects</Nav.Link>
                                    <Nav.Link href={'/'} className={'text-dark'}>Market Place</Nav.Link>
                                </>
                            )}
                        </Nav>
                        {!authenticated && (
                            <>
                                <Nav.Link href={'/login'} className={'mx-2 text-dark'}>Log in</Nav.Link>
                                <Nav.Link href={'/register'} className={'mx-2 text-dark'}>Sign up</Nav.Link>
                            </>
                        )}
                        {authenticated && (
                            <>
                                <NavDropdown
                                    title={`${user?.fname} ${user?.lname}`}
                                    id={'profile-dropdown'}
                                    className={'mx-2 text-dark'}
                                    align={'end'}
                                >
                                    <NavDropdown.Header
                                        style={{
                                            width: '300px',
                                        }}
                                    >
                                        Control panel
                                    </NavDropdown.Header>
                                    <NavDropdown.Item href={'/profile'}><FontAwesomeIcon icon={ faUser }/>{'  '} Profile</NavDropdown.Item>
                                    <NavDropdown.Item href={'/license'}><FontAwesomeIcon icon={ faIdBadge }/>{'  '} License</NavDropdown.Item>
                                    <NavDropdown.Item href={`${user?.email.split('@')[0]}/settings`}><FontAwesomeIcon icon={ faGear } />{'  '} Settings</NavDropdown.Item>
                                    <NavDropdown.Divider />
                                    <NavDropdown.Item
                                        href={'/login'}
                                        onClick={() =>  dispatch({ type: 'LOGOUT' }) }
                                        onMouseOver={() => {}}
                                    >
                                        <FontAwesomeIcon icon={ faArrowRightFromBracket }/>{'  '} Logout
                                    </NavDropdown.Item>
                                </NavDropdown>
                            </>
                        )}
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </>
    );
};

export default MainNavbar;