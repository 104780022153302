import { BrowserRouter, Routes, Route } from "react-router-dom";
import { CssBaseline } from "@mui/material";
import RegisterPage from "./pages/register.page";
import LoginPage from "./pages/login.page";
import HomePage from "./pages/home.page";
import ProfilePage from "./pages/profile.page";
import RequireUser from "./components/auth/components/requireUser";
import { useAuthContext } from "./modules/authContext";
import UserSettingsPage from "./pages/user.settings.page";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import DataTrackerPage from "./pages/data.tracker.page";

const App = () => {
    const { user } = useAuthContext();

    return (
        <>
            <CssBaseline />
            <ToastContainer />
            <BrowserRouter>
                <Routes>

                    <Route element={<RequireUser />}>
                        <Route path={'/profile'} element={<ProfilePage />} />
                        <Route path={`${user?.email.split('@')[0]}/settings`} element={<UserSettingsPage />} />

                        <Route path={'/'} element={<HomePage />} />
                        <Route path={'/worker'} />
                        <Route path={'/paymants'} />
                        <Route path={'/projects'} />
                        <Route path={'/market_place'} />
                        <Route path={'/datatracker'} element={<DataTrackerPage />} />
                    </Route>

                    <Route index path={'/login'} element={<LoginPage />} />
                    <Route path={'/register'} element={<RegisterPage />} />
                </Routes>
            </BrowserRouter>
        </>
    );
};

export default App;