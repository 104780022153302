import { object, string, TypeOf } from "zod";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
// import { useRegisterUserMutation } from "../redux/api/authApi";
import { Link, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { toast } from "react-toastify";
import { Box, Container, styled, Typography } from "@mui/material";
import CustomInput from "../redux/features/customInput";
import CustomLoadingButton from "../redux/features/customLoadingButton";

const LinkItem = styled(Link)`
  text-decoration: none;
  color: #2363eb;
  &:hover {
    text-decoration: underline;
  }
`;

const registerSchema = object({
    fname: string()
        .min(1, 'Full name is required')
        .max(100),
    lname: string()
        .min(1, 'Full last name is required')
        .max(100),
    email: string()
        .min(1, 'Email address is required')
        .email('Email address is invalid'),
    password: string()
        .min(1, 'Password is required')
        .min(8, 'Password must be more than 8 characters')
        .max(32, 'Password must be less than 32 characters'),
    passwordConfirm: string()
        .min(1, 'Password confirm your password'),
}).refine((data) => data.password === data.passwordConfirm, {
    path: ['passwordConfirm'],
    message: 'Password do not match',
});

export type RegisterInput = TypeOf<typeof registerSchema>;

const RegisterPage = () => {
    // const methods = useForm<RegisterInput>({
    //     resolver: zodResolver(registerSchema),
    // });
    //
    // const [registerUser, { isLoading, isSuccess, error, isError }] = useRegisterUserMutation();
    //
    // const navigate = useNavigate();
    // const {
    //     reset,
    //     handleSubmit,
    //     formState: { isSubmitSuccessful },
    // } = methods;
    //
    // useEffect(() => {
    //     if (isSuccess) {
    //         toast.success('You successfully logged in')
    //         navigate('/login')
    //     }
    //
    //     if (isError) {
    //         console.log(error);
    //         if (Array.isArray((error as any).data.error)) {
    //             (error as any).data.error.forEach((el: any) =>
    //                 toast.error(el.message, {
    //                     position: 'top-right',
    //                 })
    //             );
    //         } else {
    //             toast.error((error as any).data.message, {
    //                 position: 'top-right',
    //             });
    //         }
    //     }
    // }, [isLoading]);
    //
    // useEffect(() => {
    //     if (isSubmitSuccessful) {
    //         reset();
    //     }
    // }, [isSubmitSuccessful]);
    //
    // const onSubmitHandler: SubmitHandler<RegisterInput> = (values) => {
    //     registerUser(values);
    // };

    return (
        <Container
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '80vh',
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                }}
            >
                <Typography
                    textAlign={'center'}
                    component={'h1'}
                    sx={{
                        fontWeight: 600,
                        fontSize: { xs: '2rem', md: '3rem' },
                        mb: 2,
                        letterSpacing: 1,
                    }}
                >
                    Sign up to get Started!
                </Typography>

                {/*<FormProvider {...methods}>*/}
                    <Box
                        component={'form'}
                        // onSubmit={handleSubmit(onSubmitHandler)}
                        noValidate
                        autoComplete={'off'}
                        maxWidth={'27rem'}
                        width={'100%'}
                        sx={{
                            p: { xs: '1rem', sm: '2rem' },
                        }}
                    >
                        <CustomInput name={'fname'} label={'First name'} />
                        <CustomInput name={'lname'} label={'Last name'} />
                        <CustomInput name={'email'} label={'Email'} type={'email'} />
                        <CustomInput name={'password'} label={'Password'} type={'password'} />
                        <CustomInput name={'passwordConfirm'} label={'Confirm password'} type={'password'} />

                        <Typography textAlign={'center'} sx={{ fontSize: '0.9rem', mb: '1rem' }}>
                            Already have an account?{' '}
                            <LinkItem to={'/login'} >Login Here</LinkItem>
                        </Typography>

                        <CustomLoadingButton
                            loading={ false }
                        >
                            Sign up
                        </CustomLoadingButton>
                    </Box>
                {/*</FormProvider>*/}
            </Box>
        </Container>
    );
};

export default RegisterPage;