import { Navigate, Outlet, useLocation, useNavigate } from "react-router-dom";
import FullScreenLoader from "../../loaders/fullSreenLoader";
import { useAuthContext, useAuthDispatch } from "../../../modules/authContext";
import {useCookies} from "react-cookie";

const RequireUser = () => {
    const location = useLocation();
    const { authenticated, user, loading } = useAuthContext();
    const dispatch = useAuthDispatch();
    const [cookies] = useCookies(['logged_in'])

    if (loading) {
        return <FullScreenLoader />;
    }

    return (authenticated) ? (
        <Outlet />
    ) : (
        <Navigate to={'/login'} state={{ from: location }} />
    )
}

export default RequireUser;