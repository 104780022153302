import { FC } from 'react';
import { Controller, useFormContext } from "react-hook-form";
import { TextField } from "@mui/material";
import { InputProps } from "@mui/material";
import FormControl from "@mui/material/FormControl";

type IFormInputProps = {
    name: string;
    label: string;
} & InputProps;

const CustomInput: FC<IFormInputProps> = ({ name, label, type, ...otherProps }) => {
    const {
        control,
        formState: { errors },
    } = useFormContext();

    return (
        <>
            <Controller
                control={control}
                name={name}
                render={({ field }) => (
                    <FormControl fullWidth sx={{ mb: 2 }}>
                        <TextField
                            {...field}
                            label={label}
                            type={type}
                            defaultValue={''}
                            variant={'standard'}
                            size={'small'}
                            fullWidth
                            error={!!errors[name]}
                            // helperText={
                            //     errors[name] ? (errors[name]?.message as unknown as string) : ''
                            // }
                        />
                    </FormControl>
                )}
            />
        </>
    );
}

export default CustomInput;