import React, { createContext, useContext } from "react";
import { AuthState, Action } from "../../api/auth/type";

export function authReducer(state: AuthState, action: Action) {
    switch (action.type) {
        case 'LOGIN':
            return {
                ...state,
                authenticated: true,
                user: action.payload,
            };
        case 'LOGOUT':
            localStorage.clear();
            return {
                ...state,
                authenticated: false,
                user: null,
            };
        case 'POPULATE':
            return {
                ...state,
                user: {
                    ...state.user,
                    ...action.payload,
                },
            };
        case 'STOP_LOADING':
            return {
                ...state,
                loading: false,
            };
        case 'START_LOADING':
            return {
                ...state,
                loading: true
            }
        default:
            throw new Error('Unknow action type');
    }
}
