import React from 'react'

// COMPONENTS IMPORT
import LineChart from "../components/charts/LineChart";

// MUI IMPORTS
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Container from '@mui/material/Container'

const DataTrackerPage = () => {
    return (
        <>
            <Box sx={{
                maxWidth: '60%',
                maxHeight: '100%',
                width: '100%',

                paddingTop: 4,
                paddingLeft: 4
            }}>
                <Typography
                    variant={'h2'}
                    sx={{
                        paddingBottom: 4
                    }}
                >
                    Data Tracker
                </Typography>

                <LineChart />
            </Box>
        </>
    )
}

export default DataTrackerPage