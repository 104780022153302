import React, { FC } from "react";

// MUI IMPORTS
import { Box, Container, Typography } from "@mui/material";

// CSS IMPORTS
import './css/style.css'

type legendItemsColor =
    | { name: 'green' }
    | { name: 'red' }
    | { name: 'blue' }
    | { name: 'purple' }

interface LineChartProps {
    // _axisYScale?: number
    // _axisXScale?: number
    _legendItems?: [string]
    _legendItemsColor?: legendItemsColor
}

const LineChart: FC<LineChartProps> = ({}) => {
    return (
        <Container sx={{
            display: 'flex',
            justifiContent: 'center',
            alignItems: 'center',

            height: 400,

            background: 'rgba(0, 0, 0, .08)',
            borderRadius: 4
        }}>
            <Box sx={{
                display: 'flex',

                height: 380,
                width: '100%',

                background: '#fff'
            }}>
                
            </Box>
        </Container>
    )
}

export default LineChart