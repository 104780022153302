import { ThemeOptions } from '@mui/material/styles';

export const waterTheme: ThemeOptions = {
    palette: {
        mode: 'light',
        primary: {
            main: '#1976d2',
        },
        secondary: {
            main: '#f50057',
        },
        info: {
            main: '#fbc02d',
        },
    },
};