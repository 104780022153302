import { object, string, TypeOf } from "zod";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';
import { SubmitHandler, useForm, FormProvider } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { QRCodeCanvas } from "qrcode.react";

import { styled, Box, Typography } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopyright } from "@fortawesome/free-regular-svg-icons";

import CustomInput from "../redux/features/customInput";
import CustomLoadingButton from "../redux/features/customLoadingButton";

import { useAuthContext, useAuthDispatch } from "../modules/authContext";
import { login } from "../api/auth/login";
import '../assets/css/login/style.css'

const LinkItem = styled(Link)`
  text-decoration: none;
  color: #2363eb;
  &:hover {
    text-decoration: underline;
  }
`;

const loginSchema = object({
    email: string()
        .min(1, 'Email address is required')
        .email('Email address is invalid'),
    password: string()
        .min(1, 'Password is required')
        .min(8, 'Password must be more than 8 characters')
        .max(32, 'Password must be less than 32 characters')
});

type LoginInput = TypeOf<typeof loginSchema>;

const LoginPage = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [cookies, setCookie] = useCookies(['logged_in']);

    const [loading, setLoading] = useState<boolean>(false);
    const dispatch = useAuthDispatch();

    const methods = useForm<LoginInput>({
        resolver: zodResolver(loginSchema),
    })

    const {
        reset,
        handleSubmit,
        formState: { isSubmitSuccessful  },
    } = methods;

    useEffect(() => {
        if (isSubmitSuccessful) {
            reset();
        }
    }, [isSubmitSuccessful, reset]);

    const onSubmitHandler: SubmitHandler<LoginInput> = (values) => {
        setLoading(true)
        login(values.email, values.password).then(
            (response) => {
                navigate('/profile');
                window.location.reload();
                toast.success('You successfully logged in', { position: 'top-right' });
                setLoading(false);
            },
            (error) => {
                toast.error(error.response.data.message, { position: 'top-right' })
                setLoading(false);
            }
        );
    };

    const qrcode = (
        <QRCodeCanvas
            id={'qrcode'}
            value={'https://icms.krisnet.net/login'}
            bgColor={'#fff'}
            level={'H'}
        />
    )

    return (
        <div className={'img-bg'} >
            <div style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    alignItems: 'center',

                    height: '100%',

                    paddingRight: 400,
                    paddingLeft: 400,
                }}
                 className={'screen-ratio-125'}>
                <div style={{
                    display: 'flex',

                    backgroundColor: '#fff',
                    borderRadius: 4,

                    width: 450,
                    height: 650,

                    padding: 46,
                }}>
                    <Box sx={{
                        width: '100%'
                    }}>
                        <Typography textAlign={'start'}
                                    component={'h2'}
                                    sx={{
                                        fontWeight: 400,
                                        fontSize: { xs: '2rem', md: '3rem' },
                                        mb: 4,
                                        letterSpacing: 1,
                                    }}
                        >
                            Log in
                        </Typography>

                        <FormProvider {...methods}>
                            <Box component={'form'} onSubmit={handleSubmit(onSubmitHandler)} autoComplete={'off'}>
                                <CustomInput name={'email'} label="Email address" type={'email'} />
                                <CustomInput name={'password'} label="Password" type={'password'} />

                                <div style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                    marginBottom: 16,
                                }}>
                                    <div style={{
                                        display: 'flex',
                                        alignItems: 'center'
                                    }}>
                                        <Typography textAlign={'start'} sx={{ fontSize: '0.9rem' }}>
                                            Need one?{' '}
                                            <LinkItem to={'/login'}>Sign up here</LinkItem>
                                        </Typography>
                                    </div>

                                    <CustomLoadingButton loading={loading}>
                                        Login
                                    </CustomLoadingButton>
                                </div>
                            </Box>
                        </FormProvider>
                        <div className="separator">
                            <Typography sx={{
                                fontFamily: '"Segoe UI",Arial,sans-serif,"Segoe UI Emoji","Segoe UI Symbol"',
                                fontWeight: 500,
                            }}>
                                OR
                            </Typography>
                        </div>
                        <div style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            padding: 20,
                        }}>
                            <div style={{
                                width: 173,

                                padding: 20,
                                border: '2px solid black',
                                borderRadius: 10
                            }}>
                                <div>{ qrcode }</div>
                            </div>
                        </div>
                        <Typography
                            sx={{
                                fontFamily: '"Segoe UI",Arial,sans-serif,"Segoe UI Emoji","Segoe UI Symbol"',
                                fontWeight: 500,
                                mb: 4
                            }}
                            textAlign={'center'}
                        >
                            Log via ICMS Mobile app
                        </Typography>
                        <Typography
                            sx={{
                                fontFamily: '"Segoe UI",Arial,sans-serif,"Segoe UI Emoji","Segoe UI Symbol"',
                                fontWeight: 400,
                                fontSize: '0.65rem',
                                color: 'rgba(0, 0, 0, .8)'
                            }}
                            textAlign={'center'}
                        >
                            <FontAwesomeIcon icon={ faCopyright }/> All rigths recived
                        </Typography>
                    </Box>
                </div>
            </div>
        </div>
    );
};

export default LoginPage;