import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from 'react-redux';
import { store } from "./redux/store/store";

import App from './App';
import MainNavbar from "./components/navbar/navbar";
import CookieConsent from "./components/cookieConsent/cookieConsent";
import { AuthProvider } from "./modules/authContext";
import { waterTheme } from "./assets/themes/themes";
import { BgTheme } from "./components/siteBg/siteBg";

import { ThemeProvider, createTheme } from "@mui/material";

import 'bootstrap/dist/css/bootstrap.css';
import '@fortawesome/fontawesome-svg-core/styles.css'

const theme = createTheme(waterTheme)

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

root.render(
    <React.StrictMode>
        <Provider store={store}>
            <AuthProvider>
                <MainNavbar />
                <App />
                <CookieConsent />
            </AuthProvider>
        </Provider>
    </React.StrictMode>
);