import { configureStore } from "@reduxjs/toolkit";
import userReducer from '../reducers/auth/userSlice'

export const store = configureStore({
    reducer: {
        userState: userReducer,
    },
    middleware: getDefaultMiddleware => getDefaultMiddleware({}).concat([]),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;