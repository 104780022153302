import React from "react";
import { Button } from "@mui/material";
import { Spinner } from "react-bootstrap";
import { createTheme } from "@mui/material/styles";

const theme = createTheme({
    palette: {
        primary: {
            main: '#564e5b',
            dark: '#011432'
        }
    }
})

type LoadingButtonProps = {
    loading: boolean;
    children: React.ReactNode;
}

const CustomLoadingButton: React.FC<LoadingButtonProps> = ({
    children,
    loading = false,
}) => {
    return (
        <Button
            color={'primary'}
            type={'submit'}
            variant={'outlined'}
            sx={{ width: 150, }}
            size={'small'}
        >
            {loading ? (
                <div>
                    <Spinner
                        as={'span'}
                        animation={'border'}
                        size={'sm'}
                        role={'status'}
                    />
                    <span> Loading...</span>
                </div>
            ) : (
                <span>{ children }</span>
            )}
        </Button>
    )
}

export default CustomLoadingButton;