import React, { createContext, useContext, useEffect } from "react";
import { redirect } from "react-router-dom";
import { me } from "../api/userProifle/me";

import axios from "../api/userAxios";
import { AuthState, Dispatch } from "../api/auth/type";
import { authReducer } from "../reducers/auth/authReducer";
import { accessToken, refreshToken } from "../components/auth/helpers/getToken";

const initialState: AuthState = {
    user: null,
    authenticated: false,
    loading: true,
};

const AuthContext = createContext<AuthState>(initialState);
// @ts-ignore
const DispatchContext = createContext();
export const useAuthContext = () => useContext(AuthContext);
// @ts-ignore
export const useAuthDispatch: () => Dispatch = () => useContext(DispatchContext)

export const AuthProvider = ({ children }: { children: React.ReactNode }) => {
    const [state, dispatch] = React.useReducer(authReducer, initialState);
    const [location, setLocation] = React.useState();

    const userCurrentLocation = window.location.pathname

    useEffect(() => {
        const loadUser = async () => {
            try {
                const res = await me()
                dispatch({ type: 'LOGIN', payload: res.data.user })
            } catch (err) {
                console.log(err);
                dispatch({ type: 'LOGOUT' })
            } finally {
                dispatch({type: 'STOP_LOADING'})
            }
        };

        loadUser();
    }, []);

    return (
        <AuthContext.Provider value={ state }>
            <DispatchContext.Provider value={ dispatch }>
                { children }
            </DispatchContext.Provider>
        </AuthContext.Provider>
    );
};