import { useCookies } from "react-cookie";
import { useState } from "react";
import Button from "react-bootstrap/Button";
import {Outlet} from "react-router-dom";

const CookieConsent = () => {
    const [cookie, setCookie] = useCookies(['cookie_constent']);

    const setCookieConsent = () => {
        setCookie('cookie_constent', true, {
            maxAge: 3600 * 24 * 365,
            secure: true,
            sameSite: 'lax'
        });
        window.location.reload();
    }

    if(cookie.cookie_constent) return <Outlet />;

    return (
        <div style={{
            position: 'fixed',
            fontFamily: '"Segoe UI",Arial,sans-serif,"Segoe UI Emoji","Segoe UI Symbol"',
            fontSize: '1rem',
            width: '100%',
            bottom: 0,
            left: 0,
            zIndex: 999,
            padding: '12px 0',
            margin: '0 !important',
            textAlign: 'center',
            color: '#ecf0f1',
            background: '#212327'
        }}>
            <b>Do you like cookies? </b>
             🍪 We use cookies to ensure you get the best experience on our website.{'  '}
            <a href={'/gdzies'}>Read more</a>{'  '}
            <Button variant="warning" size={'sm'} onClick={setCookieConsent}>Accept all</Button>{'  '}
        </div>
    );
};

export default CookieConsent;