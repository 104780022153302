export function accessToken() {
    const tokenStr = localStorage.getItem('access');
    let token = null;
    if (tokenStr)
        token = JSON.parse(tokenStr);

    if (token) return token
    else return ''
}

 export function refreshToken() {
    const tokenStr = localStorage.getItem('refresh');
    let token = null;
    if (tokenStr)
        token = JSON.parse(tokenStr);

    if (token) return token
    else return ''
}