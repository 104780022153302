import React, {useEffect, useState, useCallback} from "react";
import {string} from "zod";

import Card from 'react-bootstrap/Card'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";

import Button from "@mui/material/Button";
import SendIcon from '@mui/icons-material/Send';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen, faUser, faLock } from "@fortawesome/free-solid-svg-icons";
import { faIdBadge } from "@fortawesome/free-regular-svg-icons";

// import {addAvatar} from "../api/userProifle/addAvatar";
import {useAuthContext} from "../modules/authContext";
import {Stack} from "react-bootstrap";


const ProfilePage = () => {
    const { user } = useAuthContext();
    // const avatar = new FormData()
    // avatar.set('avatar', (user?.avatarUrl) as any)

    const [onMouseOver, setOnMouseOver] = useState<boolean>( false )
    const [showModal, setShowModal] = useState<boolean>(false)
    const [uplodedFile, setUplodedFile] = useState()
    const [preview, setPreview] = useState()

    useEffect(() => {
        if (!uplodedFile) {
            setPreview(undefined)
            return
        }

        const url = URL.createObjectURL(uplodedFile)
        setPreview(url as any)

        return () => URL.revokeObjectURL(uplodedFile)
    }, [uplodedFile])

    const onUploadFile = (e: any) => {
        if (!e.target.files || e.target.files.length === 0) {
            setUplodedFile(undefined)
            return
        }

        setUplodedFile(e.target.files[0])
    }

    const handelSubmit = () => {
        if (uplodedFile !== typeof undefined) {
            // @ts-ignore
            // addAvatar(uplodedFile)
            setShowModal(false)
            setUplodedFile(undefined)
        } else {
            console.log('chuj ci do dupy')
        }
    }
    const handleClose = () => {
        setShowModal(false)
        setUplodedFile(undefined)
    }
    const handleShow = () => setShowModal(true)

    return (
        <>
            <div>
                <Container>
                    <Row>
                        <Col sm={3}>
                            <Card
                                className={'m-4 text-center shadow-lg'}
                                style={{
                                    width: '18rem',
                                    height: '22rem'
                                }
                            }>
                                <Card.Body>
                                    <Card.Img className={'mb-2'} variant={'top'} src={'https://api.krisnet.net/media/user_avatar/icms_logo.png'} />
                                    <Card.ImgOverlay>
                                        <div
                                            style={{ zIndex: '101' }}
                                            onMouseOver={() => setOnMouseOver(true)}
                                            onMouseOut={() => setOnMouseOver(false)}
                                        >
                                            <div style={{
                                                display: 'flex',
                                                width: '254px',
                                                height: '254px',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                opacity: '1',
                                                zIndex: '100',
                                            }}
                                                 id={'userAvatar'}
                                                 onClick={handleShow}
                                            >
                                                {onMouseOver ? (
                                                    <div style={{
                                                        display: 'flex',
                                                        background: 'rgba(0, 0, 0, 0.1)',
                                                        width: '100%',
                                                        height: '100%',
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                    }}>
                                                        <FontAwesomeIcon icon={ faPen } bounce size={'2xl'} style={{
                                                            color: '#74777b',
                                                        }}/>
                                                    </div>
                                                ) : (
                                                    <div></div>
                                                )}
                                            </div>
                                        </div>
                                    </Card.ImgOverlay>
                                    <Card.Title>{ user?.lname } { user?.fname }</Card.Title>
                                    <Card.Text className={'text-muted'}>
                                        ICMS CEO
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col sm={8}>
                            <Stack>
                                <Card
                                    className={'m-4 shadow-lg'}
                                    style={{
                                        width: '100%'
                                    }
                                }>
                                    <Card.Body>
                                        <Container>
                                            <Row className={'mb-4'}>
                                                <Col>
                                                    <div style={{
                                                        display: 'flex',
                                                        alignItems: 'center',

                                                        marginLeft: -10
                                                    }}>
                                                        <span style={{ fontSize: 18 }}>
                                                            <FontAwesomeIcon icon={ faUser } />
                                                            {'   '}Personal data
                                                        </span>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row className={'border-bottom border-dark border-opacity-25 mb-2 pb-2'}>
                                                <Col sm={2}>Full name</Col>
                                                <Col sm={8} className={'text-muted'}>{ user?.lname } { user?.fname }</Col>
                                            </Row>
                                            <Row className={'border-bottom border-dark border-opacity-25 mb-2 py-2'}>
                                                <Col sm={2}>Email</Col>
                                                <Col sm={8} className={'text-muted'}>{ user?.email }</Col>
                                            </Row>
                                            <Row className={'border-bottom border-dark border-opacity-25 mb-2 py-2'}>
                                                <Col sm={2}>Phone</Col>
                                                <Col sm={8} className={'text-muted'}>{ user?.phone }</Col>
                                            </Row>
                                            <Row className={'py-2'}>
                                                <Col sm={2}>Date of join</Col>
                                                <Col sm={8} className={'text-muted'}>{ user?.date_of_join.split('.')[0] }</Col>
                                            </Row>
                                        </Container>
                                    </Card.Body>
                                </Card>
                                <Card
                                    className={'m-4 shadow-lg'}
                                    style={{
                                        width: '100%'
                                    }
                                    }>
                                    <Card.Body>
                                        <Container>
                                            <Row className={'mb-4'}>
                                                <Col>
                                                    <div style={{
                                                        display: 'flex',
                                                        alignItems: 'center',

                                                        marginLeft: -10
                                                    }}>
                                                    <span style={{ fontSize: 18 }}>
                                                        <FontAwesomeIcon icon={ faLock } />
                                                        {'   '}Security
                                                    </span>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row className={'border-bottom border-dark border-opacity-25 mb-2 pb-2'}>
                                                <Col sm={2}>Password</Col>
                                                <Col sm={8} className={'text-muted'}>*******</Col>
                                            </Row>
                                            <Row className={'border-bottom border-dark border-opacity-25 mb-2 py-2'}>
                                                <Col sm={2}>Group identifier</Col>
                                                <Col sm={8} className={'text-muted'}>none</Col>
                                            </Row>
                                            {/*<Row className={'border-bottom border-dark border-opacity-25 mb-2 py-2'}>*/}
                                            {/*    <Col sm={2}>Phone</Col>*/}
                                            {/*    <Col sm={8} className={'text-muted'}>{ user?.phone }</Col>*/}
                                            {/*</Row>*/}
                                            {/*<Row className={'py-2'}>*/}
                                            {/*    <Col sm={2}>Date of join</Col>*/}
                                            {/*    <Col sm={8} className={'text-muted'}>{ user?.date_of_join.split('.')[0] }</Col>*/}
                                            {/*</Row>*/}
                                        </Container>
                                    </Card.Body>
                                </Card>
                            </Stack>
                        </Col>
                    </Row>
                </Container>
            </div>
            <Modal show={showModal} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Add/Change your avatar</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form
                        className={'mb-4'}
                        id={'newAvatarForm'}
                        onSubmit={handelSubmit}
                    >
                        <Form.Group>
                            <Form.Label>Your new avatar:</Form.Label>
                            <Form.Control
                                type={'file'}
                                name={'newAvatar'}
                                accept={'image/jpeg, image.png'}
                                onChange={onUploadFile}
                                autoFocus
                            />
                            <span style={{
                                fontSize: '.8rem',
                                fontFamily: '"Segoe UI",Arial,sans-serif,"Segoe UI Emoji","Segoe UI Symbol"',
                            }}>* Your image must be under 4 MB</span><br />
                            <span style={{
                                fontSize: '.8rem',
                                fontFamily: '"Segoe UI",Arial,sans-serif,"Segoe UI Emoji","Segoe UI Symbol"',
                            }}>** Picture is converted to 254 x 254</span>
                        </Form.Group>
                    </Form>
                    {uplodedFile &&
                        <div style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            height: '100%'
                        }}>
                            <img src={preview} alt={'your avatar'} style={{ width: '254px', height: '254px' }}/>
                        </div>
                    }
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        color={'primary'}
                        disabled={false}
                        size={'medium'}
                        variant={'contained'}
                        endIcon={<SendIcon />}
                        onClick={handleClose}
                        form={'newAvatarForm'}
                        type={'submit'}
                    >
                        Upload
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default ProfilePage;