import axios, {AxiosError, AxiosRequestConfig} from "axios";
import {accessToken, refreshToken} from "../components/auth/helpers/getToken";
import authAxios from "./authAxios";

const userAxios =  axios.create({
    baseURL: 'https://api.krisnet.net/api/users'
    // baseURL: 'http://localhost:8000/api/users'
})

userAxios.interceptors.request.use( function (config): AxiosRequestConfig {
    const token = accessToken()
    if(token) config.headers.Authorization = `Bearer ${token}`

    return config
})

userAxios.interceptors.response.use( null, async (err): Promise<AxiosError> => {
    console.log('chuj')
    const expectedError =
        err.response &&
        err.response.status >= 400 &&
        err.response.status < 500

    if(!expectedError) console.log(`Unexpected error: ${err}`)

    const originalRequest = err.config

    if(
        err.response.status === 401 &&
        originalRequest &&
        !originalRequest._retry
    ) {
        originalRequest._retry = true

        const refresh = refreshToken()
        const newToken = await authAxios.post('/token/refresh', { refresh })
            .then(resp => {
                localStorage.removeItem('access')
                localStorage.setItem('access', JSON.stringify(resp.data.access))
            })
    }

    return Promise.reject(err)
})

export default userAxios