import Navbar from "react-bootstrap/Navbar";
import Container from "react-bootstrap/Container";

const LoadingNav = () => {
    return (
        <>
            <Navbar bg={'light'} expand={'lg'} className={'border-bottom border-1 border-opacity-75 border-primary opacity-50'}>
                    <Container fluid>
                        <Navbar.Brand href={'/'} className={'text-primary'}>ICMS</Navbar.Brand>
                        <Navbar.Toggle aria-controls={'navbarCollapse'} />
                    </Container>
            </Navbar>
        </>
    )
}

export default LoadingNav;