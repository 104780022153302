import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { UserData } from "../../api/type";

interface IUserState {
    user: UserData | null;
}

const initialState = { user: null } as IUserState;

const userSlice = createSlice({
    name: 'userReducer',
    initialState,
    reducers: {
        logoutUser: () => initialState,
        setUser: (state, action: PayloadAction<UserData>) => {
            state.user = action.payload
        }
    },
})

export const { setUser, logoutUser } = userSlice.actions;
export default userSlice.reducer;